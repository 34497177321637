<template>
  <v-container fluid class="ma-4 pr-10">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Movimentação Cadastral</h1>
        <h2 class="secondary--text font-weight-regular">
          Inclusão de dependente eventual
        </h2>
      </v-col>
    </v-row>
    <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
      <v-row class="mt-10 align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="search.financialGroupIds"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            :loading="loadingFinancialGroups"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierIds ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="search.insuranceCarrierIds"
            :items="insuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            :loading="loadingInsuranceCarriers"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.financialGroupIds ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Contrato</label>
          <v-autocomplete
            v-model="search.contractIds"
            :items="contracts"
            item-value="id"
            :loading="loadingContracts"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierIds ? true : false"
            @input="verifyFieldsFilters(); verifyFreeMovement()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="11" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFreeMovement"
            @click="onClickInsertDependent('freeMovement')"
          >
            Movimentação livre
          </v-btn>
        </v-col>
        <v-col class="pt-0 pl-0 pb-6" cols="12" xs="12" sm="1" md="3" lg="2">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                dense
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span>Movimentação livre: escolha um contrato que permita movimentação livre</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">CPF do titular</label>
          <v-text-field
            v-model="search.cpf"
            class="mt-2"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            :rules="search.cpf ? [rule.cpf] : []"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Nome do titular</label>
          <v-text-field
            v-model.trim="search.name"
            :rules="search.name ? [rule.name, rule.validateIsAlphanumeric] : []"
            class="mt-2"
            placeholder="Informe o nome"
            maxlength="70"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters();"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Matrícula</label>
          <v-text-field
            maxlength="50"
            v-model="search.registration"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFieldFilters"
            @click.prevent="filter('filter')"
          >
            Localizar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn color="textPrimary" x-large text @click="clearFields()">
            Limpar campos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="elevation-1"
          hide-default-footer
          show-expand
          single-expand
          expanded.sync
          :sort-desc="true"
          :calculate-widths="true"
          :headers="headers"
          :items="results.content"
          item-key="contractPlanId"
          :loading="isLoading"
          @item-expanded="loadDependents"
          :page.sync="page"
          :items-per-page="10"
        >
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand, index }">
            <v-icon
              v-if="!loadingDependents && item.numberOfDependents > 0"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)">
              $expand
            </v-icon>
            <v-progress-circular
              v-if="loadingDependents && indexInLoading === index"
              indeterminate
            />
          </template>
          <template v-slot:[`item.holder`]="{ item }">
            {{ item.insuredName ? formatter.formatToTitleCase(item.insuredName) : "-" }}
          </template>
          <template v-slot:[`item.financialGroupId`]="{ item }">
            {{ formatter.formatFinancialGroup(item.financialGroupId, financialGroups) }}
          </template>
          <template v-slot:[`item.contract`]="{ item }">
            {{ item ? contructorContractName(item) : '' }}
          </template>
          <template v-slot:[`item.subContract`]="{ item }">
            {{ item ? contructorSubContractName(item) : '-' }}
          </template>
          <template v-slot:[`item.plan`]="{ item }">
            {{ item.planDescription ? item.planProductCode + " - " + item.planDescription : item.planProductCode + " - " + item.planName }}
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            {{ formatter.formatDate(item.startDate) }}
          </template>
          <template v-slot:[`item.dependents`]="{ item }">
            {{ item.numberOfDependents ? item.numberOfDependents : '0' }}
          </template>
          <template v-slot:[`item.attachments`]="{ item }">
            <v-menu open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon color="textPrimary" size="20"
                    >fas fa-ellipsis-v</v-icon
                  >
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="onClickInsertDependent(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon size="20" color="textPrimary"
                      >fas fa-user-plus</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content class="mt-1">
                    <v-list-item-title>Incluir dependente</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="mx-0 px-0">
              <v-row class="pa-0 ma-0 white">
                <v-col class="px-0 py-0" cols="12">
                  <v-data-table
                    loading-text="Carregando..."
                    dense
                    :headers="headersDependents"
                    :items="item.dependents"
                    hide-default-footer
                    class="elevation-1 px-0"
                  >
                    <template v-slot:no-data>
                      <span>
                        {{
                          loadingDependents
                            ? "Carregando..."
                            : "Este beneficiário não possui dependente."
                        }}
                      </span>
                    </template>
                    <template v-slot:[`header.holder`]>
                      <span>{{ "Dependente" }}</span>
                    </template>
                    <template v-slot:[`item.holder`]="{ item }">
                      {{ item.insuredName ? formatter.formatToTitleCase(item.insuredName) : "-" }}
                    </template>
                    <template v-slot:[`item.financialGroupId`]="{ item }">
                      {{ formatter.formatFinancialGroup(item.financialGroupId, financialGroups) }}
                    </template>
                    <template v-slot:[`item.contract`]="{ item }">
                      {{ item ? contructorContractName(item) : '' }}
                    </template>
                    <template v-slot:[`item.subContract`]="{ item }">
                      {{ item ? contructorSubContractName(item) : '-' }}
                    </template>
                    <template v-slot:[`item.plan`]="{ item }">
                      {{ item.planDescription ? item.planProductCode + " - " + item.planDescription : item.planProductCode + " - " + item.planName }}
                    </template>
                    <template v-slot:[`item.startDate`]="{ item }">
                      {{ formatter.formatDate(item.startDate) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :total-visible="9"
            :disabled="isLoading"
            @input="filter('pagination')"
          />
        </div>
      </v-col>
    </v-row>
    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Voltar
          </v-btn>
        </v-col>

        <v-snackbar
          v-model="snackbar.show"
          timeout="10000"
          top
          elevation="24"
          :color="snackbar.type"
        >
          <div class="text-center">{{ snackbar.text }}</div>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              dark
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              close
            </v-icon>
          </template>
        </v-snackbar>
      </v-row>
    </v-footer>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="120"
      >
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <v-row class="mb-3" justify="center" slot="textInfo" style="word-break: break-word">
        <v-col cols="12" align="center">
          <label class="label primary--text font-italic" style="word-break: break-word">“Ops! Algo deu errado. Isso pode ter acontecido por você ter selecionado um grande volume de informações. Tente refinar os filtros e repetir a operação”</label>
        </v-col>
      </v-row>
    </DynamicDialogInformation>

  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DependentContractedPlanService from '@/services-http/contract/DependentContractedPlanService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin'
import lodash from 'lodash';
import ContractService from '@/services-http/contract/ContractService';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ManualMovementDependentInclusionSearchHolders',
  components: { DynamicDialogInformation },
  data: () => ({
    isValidForm: true,
    overlay: false,
    dependentContractedPlanService: null,
    indexInLoading: null,
    loadingDependents: false,
    headers: [
      {
        text: 'Titular',
        value: 'holder',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Sub-Contrato',
        value: 'subContract',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Dependentes',
        value: 'dependents',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: 'Ações',
        value: 'attachments',
        align: 'center',
        sortable: false,
        class: 'text--truncate',
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        class: 'text--truncate',
      },
    ],

    headersDependents: [
      {
        text: 'Titular',
        value: 'holder',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Contrato',
        value: 'contract',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Sub-Contrato',
        value: 'subContract',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        class: 'text--truncate',
      },
      {
        text: ' ',
        align: 'right',
        value: 'data-table-expand',
        class: 'text--truncate',
      },
    ],

    search: {
      financialGroupIds: null,
      insuranceCarrierIds: null,
      contractIds: null,
      registration: null,
      cpf: null,
      name: null,
    },
    isLoading: false,
    results: [],
    financialGroups: [],
    contracts: [],
    insuranceCarriers: [],
    page: 1,
    totalPages: 0,
    isRHProtegido: false,
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingContracts: false,
    isDisableFieldFilters: true,
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    isDisableFreeMovement: true,
    widthModal: 800,
    heightCard: 600,
  }),

  async mounted() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData()
    this.clearSessionStorage();
    this.clearSessionStorage();
    if (this.$router.currentRoute.params.creation) {
      this.makePreviewSearch();
    }
    this.getInsuranceCarriers();
    await this.getFinancialGroups();
    if (this.financialGroups && this.financialGroups.length === 1) {
      this.search.financialGroupIds = this.financialGroups[0].id;
      this.verifyFieldsFilters();
    }
  },

  computed: {
    ...mapGetters({
      searchParams: 'handler/searchParams',
    }),
  },

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    ContractsMixin,
    InsuranceCarriersMixin,
    GenericFunctionsMixin,
  ],

  watch: {
    'search.financialGroupIds': {
      deep: true,
      handler(val) {
        const financialGroupIds = lodash.cloneDeep(val);
        if (financialGroupIds) {
          this.getContractsByFinancialGroupIdVerifyRhProtegido(financialGroupIds, this.isRHProtegido);
        }
      },
    },
  },

  methods: {
    ...mapMutations({
      setSearchParams: 'handler/setSearchParams',
    }),
    async makePreviewSearch() {
      if (Object.keys(this.searchParams.search).length === 0) return;

      this.search = {
        ...this.searchParams.search,
      };

      this.filter();
    },
    handlePageRefresh() {
      if (Object.keys(this.searchParams) > 0) {
        sessionStorage.setItem(
          'searchParams',
          JSON.stringify(this.searchParams)
        );
      }
    },
    setSessionStoreData() {
      if (sessionStorage.getItem('searchParams')) {
        this.setSearchParams(
          JSON.parse(sessionStorage.getItem('searchParams'))
        );

        sessionStorage.removeItem('searchParams');
      }
    },
    getPlanConcatenation(plan) {
      let planConcatenation = '';
      if (plan.productCode) {
        planConcatenation = plan.productCode;
      }

      if (planConcatenation) {
        planConcatenation += ' - ';
      }

      if (plan.description) {
        planConcatenation += plan.description;
      } else {
        planConcatenation += plan.name;
      }
      return planConcatenation;
    },
    filter(type) {
      if (this.$refs.formRegister.validate()) {
        if (this.search.financialGroupIds !== null || this.search.insuranceCarrierIds !== null || this.search.contractIds !== null || this.search.registration !== null || this.search.cpf !== null || this.search.name !== null) {
          if (type === 'filter') this.page = 1;
          const data = this.verifyParams();

          this.setSearchParams({ search: this.search, page: this.page });

          this.loadBeneficiary(data);
        }
      } else {
        this.customizeSnackbarMessage('error', 'Preencha corretamente para prosseguir');
      }
    },
    verifyParams() {
      const cpfUnmasked = this.search.cpf ? this.formatter.removeNonDigit(this.search.cpf) : null;
      const isActive = 'ACTIVE';
      const page = this.page - 1;
      const size = 10;

      const params = new URLSearchParams();
      if (this.search.financialGroupIds) {
        params.append('financialGroupIds', this.search.financialGroupIds);
      }
      if (this.search.insuranceCarrierIds) {
        params.append('insuranceCarrierIds', this.search.insuranceCarrierIds);
      }
      if (this.search.contractIds) {
        params.append('contractIds', this.search.contractIds);
      }
      if (this.search.registration) {
        params.append('registration', this.search.registration);
      }
      if (this.search.cpf) {
        params.append('cpf', cpfUnmasked);
      }
      if (this.search.name) {
        params.append('name', this.search.name);
      }

      if (this.isRHProtegido) {
        params.append('allowMovementByProtectedHR', 'true');
      }

      params.append('insuredSituation', isActive);
      params.append('page', page);
      params.append('size', size);

      return params;
    },
    isEmptyFilters() {
      return !(this.search.financialGroupIds || this.search.insuranceCarrierIds || this.search.contractIds || this.search.registration || this.search.cpf || this.search.name);
    },
    async onClickInsertDependent(item) {
      await this.requestInsuranceCarrier(item && item.contractId ? item.contractId : this.search.contractIds);
      const queryString = {
        isRHProtegido: this.isRHProtegido,
        movementType: 'INSERT_DEPENDENT',
        beneficiaryType: 'DEPENDENT',
      };

      let contractedPlanInfo = item;
      if (item === 'freeMovement') {
        contractedPlanInfo = {};
        contractedPlanInfo.financialGroupId = this.search.financialGroupIds;
        contractedPlanInfo.contractId = this.search.contractIds;
        queryString.freeMovement = true;
      }
      sessionStorage.setItem('contractedPlanInfo', JSON.stringify(contractedPlanInfo));

      this.redirectRouter('DependentInclusionSave', queryString);
    },
    async loadBeneficiary(params) {
      this.isLoading = true;
      await this.beneficiaryContractedPlanService.HolderToCreateMovement(params).then((response) => {
        if (response && response.data) {
          this.isLoading = false;
          this.totalPages = response.data.totalPages;
          this.results = response.data;
        }
      }).catch(() => {
        this.isLoading = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    onClickCancel() {
      this.setSearchParams({});
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'Movement';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    clearFields() {
      this.$refs.formRegister.reset();
      this.contracts = [];
    },
    verifyFieldsFilters() {
      if (this.search.financialGroupIds || this.search.insuranceCarrierIds || this.search.contractIds || this.search.registration || this.search.cpf || this.search.name) {
        this.isDisableFieldFilters = false;
      } else {
        this.isDisableFieldFilters = true;
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    contructorContractName(item) {
      let text = '';
      if (item) {
        text = `${item.benefitName ? item.benefitName : '-'} - ${item.contractName ? item.contractName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    contructorSubContractName(item) {
      let text = '';
      if (item) {
        text = `${item.subcontractNumber ? item.subcontractNumber : '-'} - ${item.subcontractOwnerLegalName ? item.subcontractOwnerLegalName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    async requestInsuranceCarrier(contract) {
      if (contract) {
        this.overlay = true;
        await this.contractService.FindById(contract).then(async (response) => {
          this.insuranceCarrierIds = response.data.carrier_id.id;
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
        });
      }
    },
    verifyFreeMovement() {
      this.isDisableFreeMovement = this.contracts.filter((contract) => contract.id === this.search.contractIds).some((isFreeMovement) => !isFreeMovement.allow_free_movement);
    },
    async loadDependents({ item, value }) {
      if (!value) return;
      if (item.dependents && item.dependents.length > 0) return;

      this.indexInLoading = null;

      this.indexInLoading = this.results.content.findIndex(
        el => el.insuredId === item.insuredId
              && el.contractId === item.contractId
              && el.subcontractId === item.subcontractId);

      const params = new URLSearchParams();

      if (item.financialGroupId) {
        params.append('financialGroupIds', item.financialGroupId);
      }

      if (item.contractId) {
        params.append('contractIds', item.contractId);
      }

      if (item.subcontractId) {
        params.append('subcontractIds', item.subcontractId);
      }

      this.loadingDependents = true;
      item.dependents = [];
      await this.dependentContractedPlanService.DependentToCreateMovementByInsuredId(item.contractPlanId, params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          item.dependents = response.data;
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao carregar os dependentes');
      }).finally(() => {
        this.indexInLoading = null;
        this.loadingDependents = false;
      });
    },
  },
  async created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.contractService = new ContractService();
    this.dependentContractedPlanService = new DependentContractedPlanService();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },
};
</script>
